export const local = {
  baseUrl: [process.env.SHOPPIZER_APP_ROUTER_BASE || "/"],
  // baseUrl : ['http://localhost:8089/'],
  // baseUrl: ["https://staging.vetscout.com/"],
  merchantCode: "DEFAULT",
  imageUrl: `${process.env.REACT_APP_S3_BASE_URL}/`,
  rollbarConfig: {
    accessToken: "49e1d4df105a45a6a1dcfb919052c233",
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: false,
    // enabled: process.env.REACT_APP_ENVIRONMENT === "production",
    payload: {
      environment: process.env.REACT_APP_ENVIRONMENT || "development",
    },
  },
};
